import React, { useState } from 'react'
import Grid from '@mui/material/Unstable_Grid2'
import TextField from '@mui/material/TextField'
import { getColor, getPrivacyPolicy } from '../../config/helpers/Helper'

export default function ConfirmPhone(props) {
    
    return (
        <Grid container alignItems='center' justifyContent='center' spacing={1} sx={{ textAlign: 'center' }}>
            <Grid xs={12}>
                <h2>Please enter the confirmation code sent via SMS</h2>
                {(props.site === 'solarfinder' || props.site === 'lifecompare') ? (
                    <p className='question-subtext'>We just sent a 6 digit code to {props.phone}. By submitting this form you are agreeing to our <a target='_blank' href={getPrivacyPolicy(props.site)}>Privacy Policy</a></p>
                ) : (
                    <p className='question-subtext'>We just sent a 6 digit code to {props.phone}</p>
                )}
            </Grid>
            <Grid xs={12} sm={12}>
                <TextField 
                    color={getColor(props.site)} 
                    variant='outlined' 
                    type='text'
                    value={props.confirmationCode} 
                    placeholder='123456'
                    size='large'
                    onChange={(e) => props.setConfirmationCode(e.target.value)} 
                    style={{width: '100%'}}
                    inputProps = {
                        {
                            sx: {
                                '&::placeholder': {
                                    color: 'rgb(121, 118, 118)',
                                    opacity: 1
                                },
                            },
                        }
                    }
                />
            </Grid>
            {props.hasError &&
                <p className='error-text' style={{marginTop: '1rem'}}>Error confirming phone, please check {props.phone} for a 6 digit confirmation code</p>
            }  
        </Grid>
    )
}