import React, { useState, useRef } from 'react'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Unstable_Grid2'
import InsureAgainst from '../Questions/InsureAgainst'
import Occupation from '../Questions/Occupation'
import EmploymentStatus from '../Questions/EmploymentStatus'
import DOB from '../Questions/DOB'
import Salary from '../Questions/Salary'
import Smoker from '../Questions/Smoker'
import SeriousHealthIssue from '../Questions/SeriousHealthIssue'
import MaritalStatus from '../Questions/MaritalStatus'
import Name from '../Questions/Name'
import Email from '../Questions/Email'
import Phone from '../Questions/Phone'
import ConfirmPhone from '../Questions/ConfirmPhone'
import PrivacyPolicy from '../Questions/PrivacyPolicy'
import Invalid from '../Invalid'
import Calculating from '../Calculating'
import ProgressBar from 'react-bootstrap/ProgressBar'
import Loader from '../Loader'
import * as api from '../../config/api/GuestAPI'
import { calculateFormProgress } from '../../config/helpers/Helper'

const wait = t => new Promise((resolve, reject) => setTimeout(resolve, t))

export default function DataCaptureForm(props) {
    const [stage, setStage] = useState(0)
    const [loading, setLoading] = useState(false)
    const [buttonLoading, setButtonLoading] = useState(false)
    const [invalid, setInvalid] = useState()
    const [invalidReason, setInvalidReason] = useState('')
    const [redirectUrl, setRedirectUrl] = useState('')
    const [phoneError, setPhoneError] = useState(false)
    const [codeError, setCodeError] = useState(false)
    // income protection details
    const [insureAgainst, setInsureAgainst] = useState('')
    const [occupation, setOccupation] = useState('')
    const [employmentStatus, setEmploymentStatus] = useState('')
    const [dob, setDob] = useState('')
    const [salary, setSalary] = useState('')
    const [smoker, setSmoker] = useState('')
    const [seriousHealthIssue, setSeriousHealthIssue] = useState('')
    const [maritalStatus, setMaritalStatus] = useState('')
    // personal details
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [confirmationCode, setConfirmationCode] = useState('')
    const [privacyPolicy, setPrivacyPolicy] = useState('')

    const dcForm = useRef(null)

    async function submitForm(e) {
        setLoading(true)
        if (e) {
            e.preventDefault()
        }

        // hit API to get risk level
        let risk = ''

        try {
            risk = await api.getRiskLevel(occupation)
            console.log('risk: ', risk)
        } catch(e) {
            console.log(e)
        }

        const details = {
            occupation,
            employmentStatus,
            dob,
            salary,
            smoker,
            seriousHealthIssue,
            maritalStatus,
            firstName,
            lastName,
            email,
            phone,
            risk,
            additionalDetails: 'Which of the following do you want to insure against? ' + insureAgainst,
            privacyPolicy: true
        }
        console.log('submit details: ', details)
        
        try {
            console.log('hitting add lead api...')
            let ip = await api.getIp()
            console.log('ip: ', ip)
            let response = await api.submitIyiQuotesForm(details, ip)
            console.log('repsonse: ', response)
            // let response = {
            //     isValid: true
            // }

            let age = getAge(dob) 
            console.log('age: ', age)

            // add delay
            await wait(5000)
            if (response.isValid) {
                if (parseInt(salary) < 30000 || seriousHealthIssue === 'YES' || age > 60) {
                    window.parent.location.replace('https://quotes.insureyourincome.ie/uninsurable')
                }
                else {
                    if (risk === 'Low Risk' || risk === 'Medium Risk') {
                        window.parent.location.replace('https://quotes.insureyourincome.ie/details-complete')
                    }
                    if (risk === 'High Risk') {
                        window.parent.location.replace('https://quotes.insureyourincome.ie/details-submitted')
                    }
                    if (risk === 'Uninsurable') {
                        window.parent.location.replace('https://quotes.insureyourincome.ie/uninsurable')
                    }
                }
            }
            else {
                if (response.invalidReason === 'Salary under €30,000') {
                    window.parent.location.replace('https://quotes.insureyourincome.ie/uninsurable')
                }
                else if (response.invalidReason === 'Profanity' || response.invalidReason === 'Fake Name' || response.invalidReason === 'Famous Name') {
                    //let resp = await api.sendTrollDetails(details.firstName, details.lastName, details.email, details.phone, response.invalidReason)
                    //console.log('response: ', resp)
                    window.parent.location.replace('https://youtu.be/GM-e46xdcUo?t=28')
                }
                else {
                    setInvalid(true)
                    if (response.invalidReason === 'Phone number prefix' || response.invalidReason === 'Phone number repeat' || response.invalidReason === 'Phone number sequential') {
                        setInvalidReason('Phone')
                    }
                    else if (response.invalidReason.includes('Duplicate in last')) {
                        setInvalidReason('Duplicate')
                    }
                    setLoading(false)
                }
            }
        } catch(e) {
            console.log('Error submitting form: ', e)
        }
    }

    function getAge(dateString) {
        var today = new Date()
        var birthDate = new Date(dateString)
        var age = today.getFullYear() - birthDate.getFullYear()
        var m = today.getMonth() - birthDate.getMonth()
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--
        }
        return age
    }

    async function sendSmsConfirmation() {
        setButtonLoading(true)
        try {
            let status = await api.sendSmsConfirmation(phone, 'iyi')
            if (status === 'pending') {
                updateStage(stage + 1)
                setPhoneError(false)
            }
            else {
                setPhoneError(true)
            }
            setButtonLoading(false)
        } catch(e) {
            console.log('Error sending confirmation: ', e)
            setPhoneError(true)
            setButtonLoading(false)
        }
    }

    async function checkConfirmationCode() {
        setButtonLoading(true)
        try {
            let status = await api.checkConfirmationCode(phone, confirmationCode, 'iyi')
            if (status === 'approved') {
                updateStage(stage + 1)
                setCodeError(false)
            }
            else {
                setCodeError(true)
            }
            setButtonLoading(false)
        } catch(e) {
            console.log('Error confirming phone: ', e)
            setCodeError(true)
            setButtonLoading(false)
        }
    }

    function nextStage() {
        if (stage === 10) {
            sendSmsConfirmation()
        }
        else if (stage === 11) {
            checkConfirmationCode()
        }
        else {
            setStage(stage + 1)
        }
    }

    function updateStage(newStage) {
        setStage(newStage)
        //dcForm.current.scrollIntoView()
    }

    function checkNextDisabled() {
        if (buttonLoading) {
            return true
        }
        else {
            if (stage === 0) {
                return !insureAgainst
            }
            if (stage === 1) {
                return !occupation
            }
            if (stage === 2) {
                return !employmentStatus
            }
            if (stage === 3) {
                return !dob
            }
            if (stage === 4) {
                return !salary
            }
            if (stage === 5) {
                return !smoker
            }
            if (stage === 6) {
                return !seriousHealthIssue
            }
            if (stage === 7) {
                return !maritalStatus
            }
            if (stage === 8) {
                return !firstName || !lastName
            }
            if (stage === 9) {
                return !email
            }
            if (stage === 10) {
                return !phone
            }
            if (stage === 11) {
                return !confirmationCode
            }
            if (stage === 12) {
                return !privacyPolicy
            }
        }
    }

    return (
        <>
        {loading ? (
            <Grid container justifyContent='flex-start'>
                <Grid item xs={12}>
                    <Box display='flex' justifyContent='center' alignItems='center' style={{height: '100vh'}}>
                        <Calculating 
                            redirectUrl={redirectUrl}
                            form='iyi'
                            message='Finding the best suited providers for you...'
                        />
                    </Box>
                </Grid>
            </Grid>
        ) : (
            <>
            {invalid ? (
                <Grid container justifyContent='flex-start'>
                    <Grid item xs={12}>
                        <Box display='flex' justifyContent='center' alignItems='center' style={{height: '100vh'}}>
                            <Invalid 
                                reason={invalidReason}
                            />
                        </Box>
                    </Grid>
                </Grid>
            ): (
            <form id='main-form' ref={dcForm} onSubmit={(e) => e.preventDefault()} className='solarfinder iyi'>
                <Grid container justifyContent='center' className='progress-bar-container scroll-container'>
                    <Grid xs={11}>
                        <ProgressBar animated variant='warning' now={calculateFormProgress(stage, 12, 8)} />
                    </Grid>
                </Grid>
                <Grid container justifyContent='center' className='question-container scroll-container'>
                    <Grid xs={10} className='question'>
                        {stage === 0 &&
                            <InsureAgainst 
                                answer={insureAgainst}
                                updateAnswer={(insureAgainst) => setInsureAgainst(insureAgainst)}
                                nextStage={() => nextStage()}
                                site='iyi'
                            />
                        }
                        {stage === 1 &&
                            <Occupation 
                                answer={occupation}
                                updateAnswer={(occupation) => setOccupation(occupation)}
                                nextStage={() => nextStage()}
                                site='iyi'
                            />
                        }
                        {stage === 2 &&
                            <EmploymentStatus 
                                answer={employmentStatus}
                                updateAnswer={(employmentStatus) => setEmploymentStatus(employmentStatus)}
                                nextStage={() => nextStage()}
                                site='iyi'
                            />
                        }
                        {stage === 3 &&
                            <DOB 
                                answer={dob}
                                updateAnswer={(dob) => setDob(dob)}
                                nextStage={() => nextStage()}
                                site='iyi'
                            />
                        }
                        {stage === 4 &&
                            <Salary 
                                answer={salary}
                                updateAnswer={(salary) => setSalary(salary)}
                                nextStage={() => nextStage()}
                                site='iyi'
                            />
                        }
                        {stage === 5 &&
                            <Smoker 
                                answer={smoker}
                                updateAnswer={(smoker) => setSmoker(smoker)}
                                nextStage={() => nextStage()}
                                site='iyi'
                            />
                        }
                        {stage === 6 &&
                            <SeriousHealthIssue 
                                answer={seriousHealthIssue}
                                updateAnswer={(seriousHealthIssue) => setSeriousHealthIssue(seriousHealthIssue)}
                                nextStage={() => nextStage()}
                                site='iyi'
                            />
                        }
                        {stage === 7 &&
                            <MaritalStatus 
                                answer={maritalStatus}
                                updateAnswer={(maritalStatus) => setMaritalStatus(maritalStatus)}
                                nextStage={() => nextStage()}
                                site='iyi'
                            />
                        }
                        {stage === 8 &&
                            <Name 
                                firstName={firstName}
                                lastName={lastName}
                                setFirstName={(firstName) => setFirstName(firstName)}
                                setLastName={(lastName) => setLastName(lastName)}
                                nextStage={() => nextStage()}
                                site='iyi'
                            />
                        }
                        {stage === 9 &&
                            <Email 
                                email={email}
                                setEmail={(email) => setEmail(email)}
                                nextStage={() => nextStage()}
                                site='iyi'
                            />
                        }
                        {stage === 10 &&
                            <Phone 
                                phone={phone}
                                setPhone={(phone) => setPhone(phone)}
                                nextStage={() => nextStage()}
                                site='iyi'
                                hasError={phoneError}
                            />
                        }
                        {stage === 11 &&
                            <ConfirmPhone 
                                phone={phone}
                                confirmationCode={confirmationCode}
                                setConfirmationCode={(confirmationCode) => setConfirmationCode(confirmationCode)}
                                nextStage={() => nextStage()}
                                site='iyi'
                                hasError={codeError}
                            />
                        }
                        {stage === 12 &&
                            <PrivacyPolicy 
                                privacyPolicy={privacyPolicy}
                                setPrivacyPolicy={(privacyPolicy) => setPrivacyPolicy(privacyPolicy)}
                                agree={() => submitForm()}
                                site='iyi'
                            />
                        }
                    </Grid>
                </Grid>
                <Grid container justifyContent='center' className='bottom-container scroll-container'>
                    <Grid xs={11} display='flex' justifyContent={stage > 0 ? 'space-between' : 'flex-end'} alignItems='center' className='buttons'>
                        {stage > 0 && 
                            <Button color='warning' variant='outlined' size='large' onClick={() => updateStage(stage - 1)}>Back</Button>
                        }
                        {stage < 12 && 
                            <Button disabled={checkNextDisabled()} color='warning' variant='contained' size='large' onClick={() => nextStage()}>
                                {buttonLoading ? (
                                    <Loader height={10} color='white' />
                                ) : (
                                    <>Next</>
                                )}
                            </Button>
                        }
                        {stage === 12 &&
                            <Button color='warning' variant='contained' size='large' onClick={() => submitForm()}>I Agree</Button>
                        }
                    </Grid>
                </Grid>
            </form>
            )}
            </>
        )}
        </>
    )
}