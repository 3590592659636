import React, { useState, useEffect, useRef } from 'react'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Unstable_Grid2'

import lifecompareLogo from '../resources/images/lifecompare-logo.png'
import nphLogo from '../resources/images/nph-logo.png'
import trust from '../resources/images/trust-logos.png'

function getLogo(site) {
    if (site === 'lifecompare') {
        return lifecompareLogo
    } 
    else if (site === 'nph') {
        return nphLogo
    }
}

function getButtonColor(site) {
    if (site === 'lifecompare') {
        return 'info'
    } 
    else if (site === 'nph') {
        return 'nph'
    }
}

export default function FormCover(props) {
    return (
        <Grid container alignItems='center' justifyContent='center' spacing={4} sx={{ textAlign: 'center' }}>
            <Grid xs={12}>
                <img src={getLogo(props.site)} style={{width: '250px'}} />
            </Grid>
            <Grid xs={12}>
                <h2>{props.title}</h2>
                {props.subtitle &&
                    <p className='question-subtext'>{props.subtitle}</p>
                }
            </Grid>
            <Grid xs={12}>
                <h3>{props.body}</h3>
            </Grid>
            <Grid xs={12}>
                <Button color={getButtonColor(props.site)} variant='contained' size='large' onClick={() => props.dismissCover()}>{props.buttonText}</Button>
            </Grid>
            <Grid xs={12}>
                <img src={trust} style={{width: '200px'}} />
            </Grid>
        </Grid>
    )
}