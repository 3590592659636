import React, { useState } from 'react'
import Grid from '@mui/material/Unstable_Grid2'
import Box from '@mui/material/Box'

import Small from '../../resources/images/calculator/HowMuch/Small.png'
import Medium from '../../resources/images/calculator/HowMuch/Medium.png'
import Large from '../../resources/images/calculator/HowMuch/Large.png'
import NotSure from '../../resources/images/calculator/NotSure.png'
import LightbulbGreen from '../../resources/images/light-bulb-green.png'
import LightbulbWhite from '../../resources/images/light-bulb-white.png'

const options = [
    {value: 'Small (Under 3kW)', electricBills: 'Under €1,200 / year', secondaryText: '6 panels', image: Small},
    {value: 'Medium (3kW - 5kW)', electricBills: '€1,200 - €2,000 / year', secondaryText: '7-11 panels', image: Medium},
    {value: 'Large (Over 5kW)', electricBills: 'Over €2,000 / year', secondaryText: '12-16 panels', image: Large},
    {value: 'I need advice on this', secondaryText: 'hide', image: NotSure}
]

export default function HowMuch(props) {
    if (!props.answer) {
        for (let option of options) {
            if (option.electricBills === props.electricBills) {
                props.updateAnswer(option.value)
            }
        }
    }
    
    function updateAnswer(answer) {
        props.updateAnswer(answer)
        props.nextStage()
    }

    function getClassName(value) {
        let className = 'calc-option'
        if (value === props.answer) {
            className += ' active'
        }
        return className
    }

    return (
        <Grid container alignItems='stretch' justifyContent='center' spacing={1} sx={{ textAlign: 'center' }}>
            <Grid xs={12}>
                <h2>How much solar would you like?</h2>
                {props.answer !== 'I need advice on this' ? (
                    <p className='question-subtext'>The highlighted option is recommended based on your electricity usage</p>
                ) : (
                    <p className='question-subtext hide'>Hide</p>
                )}
            </Grid>
            {options.map((option, i) => {
                return (
                    <Grid xs={6} sm={3} key={i}>
                        <Box className={getClassName(option.value)} elevation={2} onClick={() => updateAnswer(option.value)}>
                            <img src={option.image} className='calc-image' />
                            <p>{option.value}</p>
                            <p className={'secondary-text ' + (option.secondaryText === 'hide' ? 'hide' : '')}>{option.secondaryText}</p>
                            {option.electricBills === props.electricBills ? (
                                <p className='recommended-text secondary-text bold'>Recommended</p>
                            ) : (
                                <p className='recommended-text secondary-text hide'>Recommended</p>
                            )}
                        </Box>
                    </Grid>
                )
            })}
        </Grid>
    )
}