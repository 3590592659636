import React, { useState } from 'react'
import Grid from '@mui/material/Unstable_Grid2'
import Box from '@mui/material/Box'

import Immediately from '../../resources/images/calculator/Timeline/Immediately.png'
import Weeks from '../../resources/images/calculator/Timeline/8Weeks.png'
import Months from '../../resources/images/calculator/Timeline/6Months.png'
import NotSure from '../../resources/images/calculator/NotSure.png'

const options = [
    {value: 'Immediately', image: Immediately},
    {value: 'Under 8 Weeks', image: Weeks},
    {value: 'Within 6 months', image: Months},
    {value: 'Not sure yet', image: NotSure}
]

export default function Timeline(props) {
    function updateAnswer(answer) {
        props.updateAnswer(answer)
        props.nextStage()
    }

    function getClassName(value) {
        let className = 'calc-option'
        if (value === props.answer) {
            className += ' active'
        }
        return className
    }

    return (
        <Grid container alignItems='stretch' justifyContent='center' spacing={1} sx={{ textAlign: 'center', gridAutoRows: '1fr' }}>
            <Grid xs={12}>
                <h2>What's your time frame for getting solar?</h2>
                <p className='question-subtext hide'>Hide</p>
            </Grid>
            {options.map((option, i) => {
                return (
                    <Grid xs={6} sm={3} key={i}>
                        <Box className={getClassName(option.value)} elevation={2} onClick={() => updateAnswer(option.value)}>
                            <img src={option.image} className='calc-image' />
                            <p>{option.value}</p>
                        </Box>
                    </Grid>
                )
            })}
        </Grid>
    )
}