import React, { useState } from 'react'
import Grid from '@mui/material/Unstable_Grid2'
import Box from '@mui/material/Box'

const options = [
    '€150,000 (cheapest)',
    '€250,000 (most popular)',
    '€500,000 (maximum)',
    'Not sure yet'
]

export default function CoverAmount(props) {

    function updateAnswer(answer) {
        props.updateAnswer(answer)
        props.nextStage()
    }

    function getClassName(value) {
        let className = 'calc-option text-only'
        if (value === props.answer) {
            className += ' active'
        }
        return className
    }

    return (
        <Grid container alignItems='center' justifyContent='center' spacing={1} sx={{ textAlign: 'center' }}>
            <Grid xs={12}>
                <h2>How much cover would you like a quote for?</h2>
                <p className='question-subtext'>Your best guess is fine</p>
            </Grid>
            {options.map((option, i) => {
                return (
                    <Grid xs={12} sm={6} key={i}>
                        <Box className={getClassName(option)} elevation={2} onClick={() => updateAnswer(option)}>
                            <p>{option}</p>
                        </Box>
                    </Grid>
                )
            })}
        </Grid>
    )
}