import React, { useState } from 'react'
import Grid from '@mui/material/Unstable_Grid2'
import Box from '@mui/material/Box'

export default function MostImportant(props) {

    const options = [
        'Reducing my monthly energy bill',
        'Creating hot water during the day',
        'Selling electricity back to the grid',
        'All of the above'
    ]

    function updateAnswer(answer) {
        props.updateAnswer(answer)
        props.nextStage()
    }

    function getClassName(value) {
        let className = 'calc-option text-only'
        if (value === props.answer) {
            className += ' active'
        }
        return className
    }

    return (
        <Grid container alignItems='center' justifyContent='center' spacing={1} sx={{ textAlign: 'center' }}>
            <Grid xs={12}>
                <h2>What appeals to you most about solar?</h2>
                <p className='question-subtext hide'>Hide</p>
            </Grid>
            {options.map((option, i) => {
                return (
                    <Grid xs={12} sm={6} key={i}>
                        <Box className={getClassName(option)} elevation={2} onClick={() => updateAnswer(option)}>
                            <p>{option}</p>
                        </Box>
                    </Grid>
                )
            })}
        </Grid>
    )
}