import React, { useState } from 'react'
import Grid from '@mui/material/Unstable_Grid2'
import Box from '@mui/material/Box'

const options = [
    'PRSA',
    'Personal Pension',
    'Occupational Pension',
    'Executive Pension',
    'Personal Retirement Bond',
    'ARF/AMRF',
    "I'm not sure"
]

export default function PensionTypes(props) {

    function updateAnswer(answer) {
        props.updateAnswer(answer)
        //props.nextStage()
    }

    function getClassName(value) {
        let className = 'calc-option text-only'
        if (props.answer.indexOf(value) > -1) {
            className += ' active'
        }
        return className
    }

    return (
        <Grid container alignItems='center' justifyContent='flex-start' spacing={1} sx={{ textAlign: 'center' }}>
            <Grid xs={12}>
                <h2>What type of pension/s do you have?</h2>
                <p className='question-subtext'>Select all that apply</p>
            </Grid>
            {options.map((option, i) => {
                return (
                    <Grid xs={12} sm={6} key={i}>
                        <Box className={getClassName(option)} elevation={2} onClick={() => updateAnswer(option)}>
                            <p>{option}</p>
                        </Box>
                    </Grid>
                )
            })}
        </Grid>
    )
}