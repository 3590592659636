import React, { useState } from 'react'
import Grid from '@mui/material/Unstable_Grid2'
import TextField from '@mui/material/TextField'
import { getColor } from '../../config/helpers/Helper'

export default function DOB(props) {
    const [dob, setDob] = useState(props.answer)

    function updateDob(val) {
        let updated = val.replace(/^(\d{2})(\d{2})/, '$1/$2/')
        //console.log(updated)
        if (updated.length < 11) {
            setDob(updated)
            props.updateAnswer(updated)
        }
    }
    return (
        <Grid container alignItems='center' justifyContent='center' spacing={1} sx={{ textAlign: 'center' }}>
            <Grid xs={12}>
                {props.name ? (
                    <h2>{props.name}, what is your date of birth?</h2>
                ) : (
                    <h2>What is your date of birth?</h2>
                )}
                <p className='question-subtext hide'>Hide</p>
            </Grid>
            <Grid xs={12} sm={12}>
                <TextField 
                    //label='Last Name' 
                    color={getColor(props.site)} 
                    variant='outlined' 
                    type='text'
                    value={dob} 
                    placeholder='DD/MM/YYYY'
                    size='large'
                    onChange={(e) => updateDob(e.target.value)} 
                    style={{width: '100%'}}
                    inputProps = {
                        {
                            sx: {
                                '&::placeholder': {
                                    color: 'rgb(121, 118, 118)',
                                    opacity: 1
                                },
                            },
                        }
                    }
                />
            </Grid>
        </Grid>
    )
}