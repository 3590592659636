import React, { useState, useEffect, useRef } from 'react'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Unstable_Grid2'
import CircularProgress from '@mui/material/CircularProgress'
import * as api from '../config/api/GuestAPI'
import { Warning } from '@mui/icons-material'

export default function Invalid(props) {
    return (
        <Grid container spacing={2} justifyContent='center' alignItems='center' style={{height: '50%'}}>
            <Grid xs={12} display='flex' justifyContent='center' alignItems='center'> 
                <Warning sx={{ fontSize: '50px' }} color='warning' />
            </Grid>
            <Grid xs={12} display='flex' justifyContent='center' alignItems='center' style={{textAlign: 'center'}}>
                {props.reason === 'Phone' &&
                    <h3>Apologies, your information could not be processed due to issues with your phone number.</h3>
                }
                {props.reason === 'Duplicate' &&
                    <h3>Apologies, it seems your information has already been submitted.</h3>
                }
            </Grid>
            <Grid xs={12} display='flex' justifyContent='center' alignItems='center' style={{textAlign: 'center'}}>
                <Button variant='outlined' size='large' onClick={() => window.location.reload()}>Back</Button>
            </Grid>
        </Grid>
    )
}