import moment from 'moment'

export function getColor(site) {
    if (site === 'energyefficiency') {
        return 'success'
    }
    else if (site === 'solarfinder') {
        return 'warning'
    }
    else if (site === 'iyi') {
        return 'warning'
    }
    else if (site === 'lifecompare') {
        return 'info'
    }
    else if (site === 'nph') {
        return 'nph'
    }
    else {
        return 'success'
    }
}

export function getPrivacyPolicy(site) {
    if (site === 'energyefficiency') {
        return 'https://energyefficiency.ie/privacy-policy'
    }
    else if (site === 'solarfinder') {
        return 'https://solarfinder.ie/privacy-policy'
    }
    else if (site === 'iyi') {
        return 'https://insureyourincome.ie/privacy-policy'
    }
    else if (site === 'lifecompare') {
        return 'https://lifecompare.ie/privacy-policy'
    }
    else if (site === 'nph') {
        return 'https://nationalpensionhelpline.ie/privacy-policy'
    }
}

export function getLast12Months() {
    const months = []
    const currentDate = new Date()

    for (let i = 0; i < 12; i++) {
        // Create a new date object adjusted for each month
        const date = new Date(currentDate.getFullYear(), currentDate.getMonth() - i, 1)
        // Format the date as "Month Year"
        const monthYear = date.toLocaleString('default', { month: 'long', year: 'numeric' })
        months.push(monthYear)
    }

    // Reverse the array to have the most recent month first
    return months.reverse()
}

// Helper function to check if filtered plans contain possible options for a given attribute
export function hasOptionsForAttribute(filteredPlans, attribute, possibleOptions) {
    const availableOptions = new Set(filteredPlans.map(plan => plan[attribute]));
    return possibleOptions.reduce((acc, option) => {
        acc[option] = availableOptions.has(option);
        return acc;
    }, {});
}

// Rates - what is used is determined by tariffType for both current and standard plans
function getRates(plan) {
    return {
        discount: parseFloat(plan['Discount'].replace('%', '')) / 100 || 0,
        dayRate: parseFloat(plan['Day Rate (Exc VAT)']) / 100 || 0,
        nightRate: parseFloat(plan['Night Rate (Exc VAT)']) / 100 || 0,
        peakRate: parseFloat(plan['Peak Rate (Exc VAT)']) / 100 || 0,
        evRate: parseFloat(plan['EV Rate (Exc VAT)']) / 100 || 0,
        psoLevy: parseFloat(plan['PSO Levy (Exc VAT)'].replace('€', '')) || 0,
        standingCharge: parseFloat(plan['Standing Charge (Exc VAT)'].replace('€', '')) || 0
    }
}

// Helper function to calculate the cost based on plan details
function calculateUsageCost(plan, rates, nightsaverPercentage, electricityUsage, includeCharges) {
    let usageCost = 0

    if (plan['Tariff Type'] === '24hr') {
        const discountedRate = rates.dayRate * (1 - rates.discount)
        usageCost = electricityUsage * discountedRate
        //console.log('discounted rate: ', discountedRate)
    } 
    else if (plan['Tariff Type'] === 'Nightsaver') {
        const discountedDayRate = rates.dayRate * (1 - rates.discount)
        const discountedNightRate = rates.nightRate * (1 - rates.discount)
        const dayUsage = electricityUsage * (1 - nightsaverPercentage)
        const nightUsage = electricityUsage * nightsaverPercentage
        const dayCost = dayUsage * discountedDayRate
        const nightCost = nightUsage * discountedNightRate
        usageCost = dayCost + nightCost
        // console.log('day rate: ', discountedDayRate)
        // console.log('night rate: ', discountedNightRate)
        // console.log('day usage: ', dayUsage)
        // console.log('night usage: ', nightUsage)
        // console.log('day cost: ', dayCost)
        // console.log('night cost: ', nightCost)
        // console.log('usage cost: ', usageCost)
    } 
    else if (plan['Tariff Type'] === 'Smart') {
        const discountedDayRate = rates.dayRate * (1 - rates.discount)
        const discountedNightRate = rates.nightRate * (1 - rates.discount)
        const discountedPeakRate = rates.peakRate * (1 - rates.discount)
        const discountedEvRate = rates.evRate * (1 - rates.discount)

        if (rates.dayRate && !rates.nightRate && !rates.peakRate && !rates.evRate) {
            usageCost = electricityUsage * discountedDayRate
        } else if (rates.dayRate && rates.nightRate && !rates.peakRate && !rates.evRate) {
            const dayCost = electricityUsage * 0.62 * discountedDayRate
            const nightCost = electricityUsage * 0.38 * discountedNightRate
            usageCost = dayCost + nightCost
        } else if (rates.dayRate && rates.nightRate && rates.peakRate && !rates.evRate) {
            const dayCost = electricityUsage * 0.53 * discountedDayRate
            const peakCost = electricityUsage * 0.09 * discountedPeakRate
            const nightCost = electricityUsage * 0.38 * discountedNightRate
            usageCost = dayCost + peakCost + nightCost
        } else if (rates.dayRate && rates.nightRate && rates.peakRate && rates.evRate) {
            const dayCost = electricityUsage * 0.53 * discountedDayRate
            const peakCost = electricityUsage * 0.09 * discountedPeakRate
            const nightCost = electricityUsage * 0.24 * discountedNightRate
            const evCost = electricityUsage * 0.14 * discountedEvRate
            usageCost = dayCost + peakCost + nightCost + evCost
        }
    }

    // Add PSO Levy and Standing Charge to the usage cost if set to true
    if (includeCharges) {
        usageCost += (rates.psoLevy + rates.standingCharge)
    }
    return usageCost
}

export function calculateCurrentBill(plan, nightsaverPercentage, electricityTotal, tariffStart, allPlans) {
    // all calculations done excluding VAT
    console.log('nightsaver %: ', nightsaverPercentage)
    console.log('electricity total: ', electricityTotal)
    console.log('tariff start: ', tariffStart)

    // Calculate months on current plan
    const startDate = tariffStart === 'Over 12 months ago' ? moment().subtract('12', 'months') : moment(tariffStart, 'MMMM YYYY')
    const currentDate = moment()

    const monthsOnCurrentPlan = 12 - currentDate.diff(startDate, 'months')
    const monthsOnStandardPlan = 12 - monthsOnCurrentPlan
    console.log('months on current plan: ', monthsOnCurrentPlan)
    console.log('months on standard plan: ', monthsOnStandardPlan)

    const currentPlanUsage = (monthsOnCurrentPlan / 12) * electricityTotal
    const standardPlanUsage = (monthsOnStandardPlan / 12) * electricityTotal
    console.log('current plan usage: ', currentPlanUsage)
    console.log('standard plan usage: ', standardPlanUsage)

    // Find the corresponding "Standard plan" version of the current plan
    const standardPlan = allPlans.find(p =>
        p['Supplier'] === plan['Supplier'] &&
        p['Region'] === plan['Region'] &&
        p['Tariff Type'] === plan['Tariff Type'] &&
        parseFloat(p['Discount'].replace('%', '')) === 0
    )

    if (!standardPlan) {
        console.error('Standard plan not found')
        return 0
    }

    console.log('standard plan: ', standardPlan)

    // rates - what is used is determined by tariffType
    const currentPlanRates = getRates(plan)
    console.log('current plan rates: ', currentPlanRates)
    const standardPlanRates = getRates(standardPlan)

    let totalBill = 0

    // Calculate bill for current plan usage
    totalBill += calculateUsageCost(plan, currentPlanRates, nightsaverPercentage, currentPlanUsage, true)
    console.log('total bill current: ', totalBill)

    // Calculate bill for standard plan usage
    totalBill += calculateUsageCost(standardPlan, standardPlanRates, nightsaverPercentage, standardPlanUsage, false)
    console.log('total bill standard: ', totalBill)

    return totalBill
}

export function calculateAllBills(allPlans, nightsaverPercentage, electricityTotal, includeSmartPlans, includeCashback) {
    const updatedPlans = allPlans.map(plan => {
        const rates = getRates(plan)

        // Skip smart plans if includeSmartPlans is false
        if (includeSmartPlans === 'No' && plan['Tariff Type'] === 'Smart') {
            return null
        }

        // Calculate usage cost
        const usageCost = calculateUsageCost(plan, rates, nightsaverPercentage, electricityTotal)

        // Add PSO Levy and Standing Charge
        const totalAnnualBill = usageCost + rates.psoLevy + rates.standingCharge

        // Include cashback if applicable
        if (includeCashback === 'Yes' && plan['Cash Bonus'] && plan['Cash Bonus'] !== '€0') {
            const cashback = parseFloat(plan['Cash Bonus'].replace('€', '')) || 0
            return { 
                ...plan, 
                estimatedAnnualBill: totalAnnualBill - cashback 
            }
        }

        return { 
            ...plan, 
            estimatedAnnualBill: totalAnnualBill
        }
    }).filter(plan => plan !== null) // Remove null values for plans not included

    // Sort by estimated annual bill (cheapest first)
    return updatedPlans.sort((a, b) => a.estimatedAnnualBill - b.estimatedAnnualBill)
}

export function calculateFormProgress(currentStep, totalSteps, firstYSteps) {
    if (currentStep > totalSteps) {
        // Cap at 100% if the user somehow exceeds the last step
        return 100
    }

    const startProgress = 10
    const endProgressFirstY = 90

    if (currentStep === 0) {
        // Start progress at 10%
        return startProgress
    }

    if (currentStep <= firstYSteps) {
        // Calculate progress for the first Y steps, evenly distributed from 10% to 90%
        const progressPerStep = (endProgressFirstY - startProgress) / firstYSteps
        return startProgress + progressPerStep * currentStep
    } 
    else {
        const remainingSteps = totalSteps - firstYSteps
        const progressAfterFirstY = endProgressFirstY
        const endProgress = 100

        // Calculate how much progress for each step after the first Y steps
        const progressPerRemainingStep = (endProgress - progressAfterFirstY) / remainingSteps

        // Make sure we reach 100% on the last step (currentStep === totalSteps)
        return progressAfterFirstY + (currentStep - firstYSteps) * progressPerRemainingStep
    }
}