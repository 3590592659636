import React, { useState } from 'react'
import Grid from '@mui/material/Unstable_Grid2'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'

export default function InputQuestion(props) {
    return (
        <Grid container alignItems='center' justifyContent='center' spacing={1} sx={{ textAlign: 'center' }}>
            <Grid xs={12}>
                <h2>{props.question}</h2>
            </Grid>
            <Grid xs={12} sm={12}>
                <TextField 
                    //label='Last Name' 
                    //color={getColor(props.site)}
                    variant='outlined' 
                    type='number'
                    value={props.answer} 
                    placeholder={props.placeholder}
                    size='large'
                    onChange={(e) => props.updateAnswer(e.target.value)}
                    style={{width: '100%'}}
                    inputProps = {
                        {
                            sx: {
                                '&::placeholder': {
                                    color: 'rgb(121, 118, 118)',
                                    opacity: 1
                                },
                            },
                        }
                    }
                />
            </Grid>
        </Grid>
    )
}