import React, { useState } from 'react'
import Grid from '@mui/material/Unstable_Grid2'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Box from '@mui/material/Box'

export default function DropdownQuestion(props) {
    return (
        <Grid container alignItems='center' justifyContent='center' spacing={1} sx={{ textAlign: 'center' }}>
            <Grid xs={12}>
                <h2>{props.question}</h2>
            </Grid>
            <Grid xs={12} sm={12}>
                <Select
                    value={props.answer}
                    placeholder='Select you tariff'
                    onChange={(e) => props.updateAnswer(e.target.value)}
                    fullWidth
                >
                    {props.options.map((option, i) => {
                        return <MenuItem key={i} value={option.value}>{option.label}</MenuItem>
                    })}
                </Select>
            </Grid>
        </Grid>
    )
}