import React, { useState } from 'react'
import Grid from '@mui/material/Unstable_Grid2'
import TextField from '@mui/material/TextField'
import { getColor, getPrivacyPolicy } from '../../config/helpers/Helper'

function getLifecompareText(phoneCallVariant, finalStep) {
    let string = ''
    if (phoneCallVariant === 'withPhoneCall') {
        string += 'After we review your information, we will give you a complimentary phone call to answer any questions you have and provide you with quotes. '
    }
    else if (phoneCallVariant === 'withoutPhoneCall') {
        string += 'To provide you with the most accurate life insurance quotes we may reach out for any additional information regarding your health or history. '
    }
    else if (phoneCallVariant === 'verifyPhone') {
        string += 'Verify your phone number to receive your free life insurance quotes. '
    }

    if (finalStep) {
        string += 'By submitting this form you are agreeing to our <a target="_blank" href=' + getPrivacyPolicy('lifecompare') + '>Privacy Policy</a>'
    }

    return string
}

export default function Phone(props) {
    //console.log('site: ', props.site)
    //console.log('final step: ', props.finalStep)
    return (
        <Grid container alignItems='center' justifyContent='center' spacing={1} sx={{ textAlign: 'center' }}>
            <Grid xs={12}>
                <h2>What's the best number to reach you on?</h2>
                {props.site === 'iyi'  &&
                    <p className='question-subtext'>After we review your information, we will give you a complimentary phone call to answer any questions you have and provide you with quotes</p>
                }
                {props.site === 'lifecompare'  &&
                    <p className='question-subtext' dangerouslySetInnerHTML={{ __html: getLifecompareText(props.phoneCallVariant, props.finalStep)}}></p>
                }
                {(props.site === 'solarfinder' || props.site === 'energyefficiency') &&
                    <>
                        {props.finalStep ? (
                            <p className='question-subtext'>After we review your information, we will give you a complimentary phone call to answer any questions you have and provide you with quotes for the best solar setup for your requirements. By submitting this form you are agreeing to our <a target='_blank' href={getPrivacyPolicy(props.site)}>Privacy Policy</a></p>
                        ) : (
                            <p className='question-subtext'>After we review your information, we will give you a complimentary phone call to answer any questions you have and provide you with quotes for the best solar setup for your requirements</p>
                        )}
                    </>
                }
            </Grid>
            <Grid xs={12} sm={12}>
                <TextField 
                    color={getColor(props.site)} 
                    variant='outlined' 
                    type='text'
                    value={props.phone} 
                    placeholder='(086) 000 1111'
                    size='large'
                    onChange={(e) => props.setPhone(e.target.value)} 
                    style={{width: '100%'}}
                    inputProps = {
                        {
                            sx: {
                                '&::placeholder': {
                                    color: 'rgb(121, 118, 118)',
                                    opacity: 1
                                },
                            },
                        }
                    }
                />
            </Grid>
            {props.hasError &&
                <p className='error-text' style={{marginTop: '1rem'}}>Error sending verification code, please enter a valid Irish mobile number</p>
            }  
        </Grid>
    )
}