import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import './index.scss'
import App from './App'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { BrowserRouter } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css'

import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'

// const { palette } = createTheme()
// const { augmentColor } = palette
// const createColor = (mainColor) => augmentColor({ color: { main: mainColor } })

const theme = createTheme({
	palette: {
	  	primary: {
			main: '#229626',
			success: '#229626'
	  	},
		nph: {
			main: '#015f63',
			contrastText: "#fff" //button text white instead of black
		}
	  	// secondary: {
		// 	main: '#f44336',
	  	// },
	}
})


const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  	<React.StrictMode>
		<ThemeProvider theme={theme}>
			<BrowserRouter>
				<App />
			</BrowserRouter>
		</ThemeProvider>
  	</React.StrictMode>
)