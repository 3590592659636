import React, { useState } from 'react'
import Grid from '@mui/material/Unstable_Grid2'
import Box from '@mui/material/Box'

import orangeSmoker from '../../resources/images/calculator/Smoker/smoker.png'
import orangeNonSmoker from '../../resources/images/calculator/Smoker/non-smoker.png'

import blueSmoker from '../../resources/images/calculator/LifeCompare/Smoker/Smoker.png'
import blueNonSmoker from '../../resources/images/calculator/LifeCompare/Smoker/NonSmoker.png'

export default function SmokerQuestion(props) {
    const options = [
        {value: 'Smoker', image: getImage('Smoker')},
        {value: 'Non-Smoker', image: getImage('Non-Smoker')}
    ]

    function getImage(image) {
        if (props.site === 'iyi') {
            if (image === 'Smoker') {
                return orangeSmoker
            }
            if (image === 'Non-Smoker') {
                return orangeNonSmoker
            }
        }
        if (props.site === 'lifecompare') {
            if (image === 'Smoker') {
                return blueSmoker
            }
            if (image === 'Non-Smoker') {
                return blueNonSmoker
            }
        }
    }

    function updateAnswer(answer) {
        props.updateAnswer(answer)
        props.nextStage()
    }

    function getClassName(value) {
        let className = 'calc-option'
        if (value === props.answer) {
            className += ' active'
        }
        return className
    }

    return (
        <Grid container alignItems='center' justifyContent='center' spacing={1} sx={{ textAlign: 'center' }}>
            <Grid xs={12}>
                <h2>Do you smoke?</h2>
                <p className='question-subtext'>Have you smoked or used nicotine products in the past 12 months</p>
            </Grid>
            {options.map((option, i) => {
                return (
                    <Grid xs={12} sm={4} key={i}>
                        <Box className={getClassName(option.value)} elevation={2} onClick={() => updateAnswer(option.value)}>
                            <img src={option.image} className='calc-image' />
                            <p>{option.value}</p>
                        </Box>
                    </Grid>
                )
            })}
        </Grid>
    )
}