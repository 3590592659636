import React, { useState } from 'react'
import Grid from '@mui/material/Unstable_Grid2'
import Box from '@mui/material/Box'

import Bungalow from '../../resources/images/calculator/Stories/Bungalow.png'
import Two from '../../resources/images/calculator/Stories/Two.png'
import Three from '../../resources/images/calculator/Stories/Three.png'

const options = [
    {value: 'Bungalow', image: Bungalow},
    {value: 'Two-storey', image: Two},
    {value: 'Three-storey', image: Three}
]

export default function Stories(props) {
    function updateAnswer(answer) {
        props.updateAnswer(answer)
        props.nextStage()
    }

    function getClassName(value) {
        let className = 'calc-option'
        if (value === props.answer) {
            className += ' active'
        }
        return className
    }

    return (
        <Grid container alignItems='stretch' justifyContent='center' spacing={1} sx={{ textAlign: 'center' }}>
            <Grid xs={12}>
                <h2>How many storeys does your house have?</h2>
                <p className='question-subtext hide'>Hide</p>
            </Grid>
            {options.map((option, i) => {
                return (
                    <Grid xs={6} sm={4} key={i}>
                        <Box className={getClassName(option.value)} elevation={2} onClick={() => updateAnswer(option.value)}>
                            <img src={option.image} className='calc-image' />
                            <p>{option.value}</p>
                        </Box>
                    </Grid>
                )
            })}
        </Grid>
    )
}