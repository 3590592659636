import React, { useState, useEffect, useRef } from 'react'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Unstable_Grid2'
import Calculating from '../Calculating'
import Invalid from '../Invalid'
import InputQuestion from '../Comparison/InputQuestion'
import OptionsQuestion from '../Comparison/OptionsQuestion'
import DropdownQuestion from '../Comparison/DropdownQuestion'
import * as api from '../../config/api/GuestAPI'
import { validEmail, providerOptions, regionOptions, tariffTypeOptions, paymentMethodOptions,  } from '../../config/constants/Constants'
import { getLast12Months, calculateCurrentBill, calculateAllBills, hasOptionsForAttribute } from '../../config/helpers/Helper'

const wait = t => new Promise((resolve, reject) => setTimeout(resolve, t))

export default function DataCaptureForm(props) {
    const [stage, setStage] = useState('calculator')
    const [loading, setLoading] = useState(false)
    const [invalid, setInvalid] = useState()
    const [invalidReason, setInvalidReason] = useState('')
    const [emailError, setEmailError] = useState(false)
    const [allPlans, setAllPlans] = useState([])
    const [plans, setPlans] = useState([])
    const [planPaymentMethods, setPlanPaymentMethods] = useState([])
    // electricity details
    const [supplier, setSupplier] = useState('')
    const [region, setRegion] = useState('Urban')
    const [tariffType, setTariffType] = useState('24hr')
    const [nightsaverUsage, setNightsaverUsage] = useState('')
    const [paymentMethod, setPaymentMethod] = useState('')
    const [onlineBilling, setOnlineBilling] = useState('Yes')
    const [planId, setPlanId] = useState('')
    const [tariffStart, setTariffStart] = useState('')
    const [electricityUsage, setElectricityUsage] = useState('No, use National Average')
    const [electricityUsageExact, setElectricityUsageExact] = useState('')
    // results details
    const [includeSmartPlans, setIncludeSmartPlans] = useState('Yes')
    const [includeCashback, setIncludeCashback] = useState('Yes')
    const [sendEmail, setSendEmail] = useState(false)
    const [email, setEmail] = useState('')
    // results
    const [yourBill, setYourBill] = useState(0)
    const [results, setResults] = useState([])

    const dcForm = useRef(null)

    useEffect(() => {
        getPlans()
    }, [])

    async function getPlans() {
        try {
            const plans = await api.getElectricityPlans()
            console.log('plans: ', plans.length)
            console.log('plans: ', plans)
            setAllPlans(plans)
            setPlans(plans)
        } catch(e) {
            console.log('Error getting plans: ', e)
        }
    }

    async function submitForm(e) {
        if (e) {
            e.preventDefault()
        }
        if (!supplier || !region || !planId || !tariffType) {
            alert('Please fill out all required fields')
            return
        }
        
        setLoading(true)
        const selectedPlan = plans.find(plan => plan['ID'] === planId)
        console.log('selected plan: ', selectedPlan)

        // Calculation 1 - Current bill on selected plan
        const nightsaverPercentage = (parseFloat(nightsaverUsage) / 100) || 0.38 // what user entered or national average
        const electricityTotal = parseFloat(electricityUsageExact) || 4200 // what user entered or national average
        const currentBill = calculateCurrentBill(selectedPlan, nightsaverPercentage, electricityTotal, tariffStart, allPlans)
        console.log('estimated annual bill: €', currentBill.toFixed(2))

        // Calculation 2 - Bills of all other plans based on inputs - orderd from cheapest to most expensive
        const allBills = calculateAllBills(allPlans, nightsaverPercentage, electricityTotal, includeSmartPlans, includeCashback)
        console.log('all other plans: ', allBills)

        setLoading(false)
        setStage('results')
        setYourBill(currentBill)
        setResults(allBills)

        // const details = {
        //     supplier,
        //     region,
        //     tariffType,
        //     nightsaverUsage,
        //     paymentMethod,
        //     onlineBilling,
        //     tariffName,
        //     tariffStart,
        //     electricityUsage,
        //     includeSmartPlans,
        //     includeCashback,
        //     sendEmail
        // }
        // console.log('submit details: ', details)
        
        // try {
        //     console.log('hitting add lead api...')
        //     let ip = await api.getIp()
        //     console.log('ip: ', ip)
        //     let response = await api.submitEeForm(details, ip)
        //     console.log('repsonse: ', response)
        //     // add delay
        //     await wait(7000)
        //     if (response.isValid) {
        //         let provider = response.companyId ? response.companyId.toLowerCase() : ''
        //         console.log('provider: ', provider)
        //         window.parent.location.replace('https://energyefficiency.ie/solar-recommendation?p=' + provider)
        //     }
        //     else {
        //         if (response.invalidReason === 'Profanity' || response.invalidReason === 'Fake Name' || response.invalidReason === 'Famous Name') {
        //             await api.sendTrollDetails(details.firstName, details.lastName, details.email, details.phone, response.invalidReason)
        //             //console.log('response: ', resp)
        //             window.parent.location.replace('https://youtu.be/GM-e46xdcUo?t=28')
        //         }
        //         else {
        //             setInvalid(true)
        //             if (response.invalidReason === 'Phone number prefix' || response.invalidReason === 'Phone number repeat' || response.invalidReason === 'Phone number sequential') {
        //                 setInvalidReason('Phone')
        //             }
        //             else if (response.invalidReason.includes('Duplicate in last')) {
        //                 setInvalidReason('Duplicate')
        //             }
        //             setLoading(false)
        //         }
        //     }
        // } catch(e) {
        //     console.log('Error submitting form: ', e)
        // }
    }

    function updateSupplier(supplier) {
        setSupplier(supplier)
        // set payment method and online billing to first plan for selected supplier
        const firstMatchingPlan = allPlans.find(plan => plan['Supplier'] === supplier && plan['Region'] === region && plan['Tariff Type'] === tariffType)
        console.log('firstMatchingPlan: ', firstMatchingPlan)
        const paymentMethod = firstMatchingPlan['Payment Type'].split(',')[0]
        const onlineBilling = firstMatchingPlan['Online Billing'].split('/')[0]
        setPaymentMethod(paymentMethod)
        setOnlineBilling(onlineBilling)
        filterPlans(supplier, region, tariffType, paymentMethod, onlineBilling, 'supplier')
    }

    function updateRegion(region) {
        setRegion(region)
        filterPlans(supplier, region, tariffType, paymentMethod, onlineBilling, 'region')
    }

    function updateTariffType(tariffType) {
        setTariffType(tariffType)
        filterPlans(supplier, region, tariffType, paymentMethod, onlineBilling, 'tariffType')
    }

    function updatePaymentMethod(paymentMethod) {
        setPaymentMethod(paymentMethod)
        filterPlans(supplier, region, tariffType, paymentMethod, onlineBilling, 'paymentMethod')
    }

    function updateOnlineBilling(onlineBilling) {
        setOnlineBilling(onlineBilling)
        filterPlans(supplier, region, tariffType, paymentMethod, onlineBilling, 'onlineBilling')
    }

    function filterPlans(supplier, region, tariffType, paymentMethod, onlineBilling, question) {
        // Map the front-end 'Yes'/'No' to back-end 'Y'/'N'
        const onlineBillingValue = onlineBilling === 'Yes' ? 'Y' : onlineBilling === 'No' ? 'N' : ''
        // Filter the allPlans array based on the provided criteria
        console.log('criteria: ', {
            supplier,
            region,
            tariffType,
            paymentMethod,
            onlineBillingValue,
            question
        })
        const filteredPlans = allPlans.filter(plan => {
            return (!supplier || plan.Supplier === supplier) &&
                   (!region || plan.Region === region) &&
                   (!tariffType || plan['Tariff Type'] === tariffType) &&
                   (!paymentMethod || plan['Payment Type'].includes(paymentMethod)) && 
                   (!onlineBilling || plan['Online Billing'].includes(onlineBillingValue))
        })
    
        // Update the filtered plans state
        setPlans(filteredPlans)
        console.log('filtered plans: ', filteredPlans)
        if (question === 'supplier') {
            console.log('payment methods: ', filteredPlans.flatMap(plan => plan['Payment Type'].split(', ').map(type => type.trim())))
            setPlanPaymentMethods(filteredPlans.flatMap(plan => plan['Payment Type'].split(', ').map(type => type.trim())))
        }
        console.log('filtered plans: ', filteredPlans.length)
    }

    function updatePlanId(id) {
        const selectedPlan = plans.find(plan => plan['ID'] === id)
        setPlanId(selectedPlan['ID'])
    }

    return (
        <>
        {loading ? (
            <Grid container justifyContent='flex-start'>
                <Grid item xs={12}>
                    <Box display='flex' justifyContent='center' alignItems='center' style={{height: '100vh'}}>
                        <Calculating 
                            message='Finding plans that can save you money...'
                        />
                    </Box>
                </Grid>
            </Grid>
        ) : (
            <>
            {invalid ? (
                <Grid container justifyContent='flex-start'>
                    <Grid item xs={12}>
                        <Box display='flex' justifyContent='center' alignItems='center' style={{height: '100vh'}}>
                            <Invalid 
                                reason={invalidReason}
                            />
                        </Box>
                    </Grid>
                </Grid>
            ): (
                <form ref={dcForm} onSubmit={(e) => e.preventDefault()} style={{padding: '2%'}} className='comparison-form'>
                    <Grid container justifyContent='center' className='question-container'>
                        <Grid xs={10} className='question'>
                            <OptionsQuestion
                                question='Select your current electricity supplier:'
                                options={providerOptions}
                                answer={supplier}
                                updateAnswer={(supplier) => updateSupplier(supplier)}
                                site='energyefficiency'
                                xs={12}
                                sm={3}
                            />
                        </Grid>
                        {supplier &&
                            <>
                                <Grid xs={10} className='question'>
                                    <OptionsQuestion
                                        question='What is your Electricity supply region?'
                                        options={regionOptions}
                                        answer={region}
                                        updateAnswer={(region) => updateRegion(region)}
                                        site='energyefficiency'
                                        xs={12}
                                        sm={6}
                                    />
                                </Grid>
                                <Grid xs={10} className='question'>
                                    <OptionsQuestion
                                        question='What type of electricity tariff do you currently have?'
                                        options={tariffTypeOptions}
                                        answer={tariffType}
                                        updateAnswer={(tariffType) => updateTariffType(tariffType)}
                                        site='energyefficiency'
                                        xs={12}
                                        sm={4}
                                    />
                                </Grid>
                                {tariffType === 'Nightsaver' &&
                                    <Grid xs={10} className='question'>
                                        <InputQuestion
                                            question='What percentage of your electricity is during Nightsaver hours?'
                                            type='number'
                                            placeholder='38 (National Average)'
                                            answer={nightsaverUsage}
                                            updateAnswer={(nightsaverUsage) => setNightsaverUsage(nightsaverUsage)}
                                            site='energyefficiency'
                                        />
                                    </Grid>
                                }
                                <Grid xs={10} className='question'>
                                    <OptionsQuestion
                                        question='How do you pay your electricity bills?'
                                        options={paymentMethodOptions.map(option => ({
                                            ...option,
                                            disabled: planPaymentMethods.length > 0 ? !planPaymentMethods.includes(option.value) : false
                                        }))}
                                        answer={paymentMethod}
                                        updateAnswer={(paymentMethod) => updatePaymentMethod(paymentMethod)}
                                        site='energyefficiency'
                                        xs={12}
                                        sm={3}
                                    />
                                </Grid>
                                <Grid xs={10} className='question'>
                                    <OptionsQuestion
                                        question='Do you use online billing?'
                                        options={
                                            [
                                                {value: 'Yes', label: 'Yes', disabled: false},
                                                {value: 'No', label: 'No', disabled: false}
                                            ]
                                        }
                                        answer={onlineBilling}
                                        updateAnswer={(onlineBilling) => updateOnlineBilling(onlineBilling)}
                                        site='energyefficiency'
                                        xs={12}
                                        sm={6}
                                    />
                                </Grid>
                                <Grid xs={10} className='question'>
                                    <DropdownQuestion
                                        question='What is your tariff called?'
                                        options={plans.map((plan, index) => ({
                                            label: plan['Tariff Name'],
                                            value: plan['ID']
                                        }))}
                                        plans={plans}
                                        answer={planId}
                                        updateAnswer={(id) => updatePlanId(id)}
                                        site='energyefficiency'
                                    />
                                </Grid>
                                <Grid xs={10} className='question'>
                                    <DropdownQuestion
                                        question='When did you sign up to this tariff?'
                                        options={[{ label: 'Over 12 months ago', value: 'Over 12 months ago' }, ...getLast12Months().map((month) => ({
                                            label: month,
                                            value: month
                                        }))]}
                                        answer={tariffStart}
                                        updateAnswer={(tariffStart) => setTariffStart(tariffStart)}
                                        site='energyefficiency'
                                    />
                                </Grid>
                                <Grid xs={10} className='question'>
                                    <OptionsQuestion
                                        question='Do you know how much electricity you use?'
                                        options={
                                            [
                                                {value: 'Yes', label: 'Yes', disabled: false},
                                                {value: 'No, use National Average', label: 'No, use National Average', disabled: false}
                                            ]
                                        }
                                        answer={electricityUsage}
                                        updateAnswer={(electricityUsage) => setElectricityUsage(electricityUsage)}
                                        site='energyefficiency'
                                        xs={12}
                                        sm={6}
                                    />
                                </Grid>
                                {electricityUsage === 'Yes' &&
                                    <Grid xs={10} className='question'>
                                        <InputQuestion
                                            question='How much electricity do you user per year? (kWh)'
                                            type='number'
                                            placeholder='4,200 (National Average)'
                                            answer={electricityUsageExact}
                                            updateAnswer={(electricityUsageExact) => setElectricityUsageExact(electricityUsageExact)}
                                            site='energyefficiency'
                                        />
                                    </Grid>
                                }
                            </>
                        }
                    </Grid>
                    <Grid container justifyContent='center' >
                        <Grid xs={10} className='question'>
                            <OptionsQuestion
                                question='Include smart plans in results?'
                                options={
                                    [
                                        {value: 'Yes', label: 'Yes', disabled: false},
                                        {value: 'No', label: 'No', disabled: false}
                                    ]
                                }
                                answer={includeSmartPlans}
                                updateAnswer={(includeSmartPlans) => setIncludeSmartPlans(includeSmartPlans)}
                                site='energyefficiency'
                                xs={12}
                                sm={6}
                            />
                        </Grid>
                        <Grid xs={10} className='question'>
                            <OptionsQuestion
                                question='Include cashback in calculations?'
                                options={
                                    [
                                        {value: 'Yes', label: 'Yes', disabled: false},
                                        {value: 'No', label: 'No', disabled: false}
                                    ]
                                }
                                answer={includeCashback}
                                updateAnswer={(includeCashback) => setIncludeCashback(includeCashback)}
                                site='energyefficiency'
                                xs={12}
                                sm={6}
                            />
                        </Grid>
                    </Grid>
                    <Grid container justifyContent='center' className='bottom-container scroll-container'>
                        <Grid xs={11} display='flex' justifyContent={stage > 0 ? 'space-between' : 'flex-end'} alignItems='center' className='buttons'>
                                <Button variant='contained' size='large' onClick={() => submitForm()}>Find Cheaper Plans</Button>
                        </Grid>
                    </Grid>
                </form>
            )}
            {results.length > 0 &&
                <>
                    <Grid container justifyContent='center' alignItems='center'>
                        <Grid item xs={10} style={{textAlign: 'center', marginTop: '2rem', marginBottom: '1rem'}}>
                            
                        </Grid>
                        <Grid item xs={10} style={{textAlign: 'center', marginTop: '2rem', marginBottom: '1rem'}}>
                            <h3>We calculate that your current energy costs for the next 12 months are €{yourBill.toFixed(2)}</h3>
                            <p>€{(yourBill * 1.09).toFixed(2)} Incl. VAT at 9.0%</p>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent='center' alignItems='center' spacing={2}>
                        <Grid item xs={10}>
                            <p>Showing {results.length} plans</p>
                        </Grid>
                        {results.map((plan) => {
                            return (
                                <Grid item xs={10} key={plan['ID']}>
                                    <Box className='plan-container'>
                                        <p className='plan-title'>{plan['Supplier']} - {plan['Tariff Name']}</p>
                                        <p>€{plan['estimatedAnnualBill'].toFixed(2)} {includeCashback === 'Yes' ? '(Includes Cashback of ' + plan['Cash Bonus'] + ')' : ''}</p>
                                    </Box>
                                </Grid>
                            )
                        })}
                    </Grid>
                </>
            }
            </>
        )}
        </>
    )
}